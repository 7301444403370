import React from 'react'
import { Modal, ModalSuccessView } from 'components'

function ModalShare({ open, close }) {
  return (
    <Modal
      open={open}
      close={close}
    >
      <ModalSuccessView message="Share text has been copied to clipboard" />
    </Modal>
  )
}

export default ModalShare
