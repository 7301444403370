import React from 'react'
import styles from './CheckIcon.module.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'

function CheckIcon() {
  return (
    <div className={styles.root}>
      <FontAwesomeIcon icon={faCheck} />
    </div>
  )
}

export default CheckIcon
