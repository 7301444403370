import React, { useState } from 'react'
import styles from './SavedListDrawer.module.css'
import Drawer from 'rc-drawer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faMapMarker, faShareSquare } from '@fortawesome/free-solid-svg-icons'
import {
  SwipeableList,
  SwipeableListItem,
  SwipeAction,
  TrailingActions
} from 'react-swipeable-list';
import { faArrowAltCircleRight, faCopy } from '@fortawesome/free-regular-svg-icons'
import { Modal, ModalShare, ModalSuccessView } from 'components'
import copy from 'copy-to-clipboard'

function SavedListDrawer({ open, close, savedAddresses, handleDirection, handleDeleteAddress }) {
  const [modalCopy, setModalCopy] = useState(false);
  const [modalShare, setModalShare] = useState(false);

  const contentWrapperStyle = {
    backgroundColor: 'rgb(248, 249, 251)'
  }

  const handleCopy = (digitalAddress) => {
    copy(digitalAddress);
    setModalCopy(true)
  }

  const handleShareClick = (digitalAddress) => {
    const shareURL = digitalAddress ? `https://minimap.loca.la/?q=${digitalAddress}` : null
    const shareText = digitalAddress ? `Digital Address: ${digitalAddress}\nGo to the maps: ` : null
  
    if (navigator && navigator.share !== undefined) {
      navigator.share({
        title: '',
        text: shareText,
        url: shareURL
      }).then(() => console.log('Successful share'))
      .catch(error => console.log('Error sharing:', error))
    } else {
      copy(`${shareText}${shareURL}`);
      setModalShare(true)
    }
  }

  const trailingActions = (address) => (
    <TrailingActions>
      <SwipeAction
        destructive={true}
        className={styles.swipeAction}
        onClick={() => handleDeleteAddress(address)}
      >
        Delete
      </SwipeAction>
    </TrailingActions>
  );

  return (
    <Drawer open={open} width="100vw" placement="right" handler={null} onClose={close} contentWrapperStyle={contentWrapperStyle}>
      <div className={styles.header}>
        <button onClick={close}>
          <FontAwesomeIcon icon={faArrowLeft} />
        </button>
        Saved List
      </div>
      <Modal
        open={modalCopy}
        close={() => setModalCopy(false)}
      >
        <ModalSuccessView message="Address has been copied to clipboard" />
      </Modal>
      <ModalShare
        open={modalShare}
        close={() => setModalShare(false)}
      />
      <div className={styles.listwrapper}>
        <SwipeableList>
          {
            savedAddresses.map((address, idx) => 
              <SwipeableListItem
                key={idx}
                trailingActions={trailingActions(address)}
              >
                <div className={styles.listItem}>
                  <div className={styles.icon}>
                    <FontAwesomeIcon icon={faMapMarker} color="#D2DCFF" />
                  </div>
                  <div className={styles.content}>
                    <h6 className={styles.name}>{ address.name }</h6>
                    <p className={styles.address}>{address.digital_address}</p>
                    <div className={styles.separator} />
                    <p className={styles.note}>{address.note}</p>
                    <div className={styles.separator} />
                    <div className={styles.grid}>
                      <button onClick={() => handleDirection({lng: address.lng, lat: address.lat })}>
                        <FontAwesomeIcon icon={faArrowAltCircleRight} color="#436AD1" /> Directions
                      </button>
                      <button onClick={() => handleCopy(address.digital_address)}>
                        <FontAwesomeIcon icon={faCopy} color="#436AD1" /> Copy
                      </button>
                      <button onClick={() => handleShareClick(address.digital_address)}>
                        <FontAwesomeIcon icon={faShareSquare} color="#436AD1" /> Share
                      </button>
                    </div>
                  </div>
                </div>
              </SwipeableListItem>
            )
          }
        </SwipeableList>
      </div>
    </Drawer>
  )
}

export default SavedListDrawer
