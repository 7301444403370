import React from 'react'
import cx from 'classnames';
import styles from './SavedListButton.module.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBookmark } from '@fortawesome/free-regular-svg-icons'

function SavedListButton({ sheetOpen, handleSaveListClick }) {
  return (
    <button
      className={cx({
        [styles.root]: true,
        [styles.slideUp]: sheetOpen,
      })}
      onClick={handleSaveListClick}
    >
      <FontAwesomeIcon icon={faBookmark} />
      Saved List
    </button>
  )
}

export default SavedListButton
