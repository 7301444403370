import React, { useRef, useState, useCallback, useMemo } from 'react';
import styles from './Search.module.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes, faLocationArrow } from '@fortawesome/free-solid-svg-icons'
import InputMask from 'react-input-mask';
import debounce from 'lodash.debounce';

function Search({ name, profileImage, handleSearch }) {
  const [term, setTerm] = useState('');
  const searchInput = useRef(null);

  const handleClear = useCallback((e) => {
    setTerm('')
    searchInput.current.value = ''
  }, [])

  const debounceSearch = useMemo(
    () =>
      debounce(val => {
        handleSearch(val)
      }, 500),
    [handleSearch]
  );

  const handleSearchChange = useCallback((e) => {
    const term = e.target.value.toUpperCase().replace(/\s/g, '')
    setTerm(term)
    debounceSearch(term)
  }, [debounceSearch])

  const imgSrc = profileImage || '/images/avatar.png'
  const imgAlt = name || 'Avatar'

  return (
    <div className={styles.root}>
      <FontAwesomeIcon icon={faLocationArrow} color="#8786F6" />
      <InputMask ref={searchInput} type="text" mask="aa 9999 9999" value={term} onChange={handleSearchChange} maskChar="" placeholder="Search digital address here" />
      { term &&
        <button onClick={handleClear}>
          <FontAwesomeIcon icon={faTimes} />
        </button>
      }
      <img src={imgSrc} alt={imgAlt} />
    </div>
  )
}

export default Search
