import React, { useState } from 'react'
import styles from './BookmarkForm.module.css'

function BookmarkForm({ onSuccess }) {
  const [name, setName] = useState('');
  const [note, setNote] = useState('');
  const submitDisabled = !name;

  const handleSubmit = () => {
    onSuccess({ name, note })
  }

  return (
    <div className={styles.root}>
      <input type="text" placeholder="Name" name="name" value={name} onChange={(e) => setName(e.target.value)} />
      <textarea placeholder="Note" name="note" rows="4" value={note} onChange={(e) => setNote(e.target.value)} />
      <button onClick={handleSubmit} disabled={submitDisabled}>Save</button>
    </div>
  )
}

export default BookmarkForm
