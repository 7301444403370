import React from "react";
import styles from "./Modal.module.css";
import cx from "classnames";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'

function Modal({ open, children, close, header }) {
  return (
    <div className={cx({
        [styles.root]: true,
        [styles.open]: open,
      })}
    >
      <div className={styles.container}>
        <div className={styles.header}>
          { header && <h3>{header}</h3>}
          <button onClick={close}>
            <FontAwesomeIcon icon={faTimes} />
          </button>
        </div>
        <div className={styles.content}>
          { children }
        </div>
      </div>
    </div>
  )
}
export default Modal;
