import React, { useEffect, useState, useCallback } from 'react';
import styles from './HomeScreen.module.css';
import { useLocation } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import { addressAxios, baseAxios } from "loca-axios";
import { requests } from "api";
import { Map, Modal, ModalShare, ModalSuccessView, BookmarkForm, SavedListButton, SavedListDrawer, Search, Sheet } from 'components'
import Loader from "react-loader-spinner";
import copy from 'copy-to-clipboard';

function LoadingIndicator () {
  return (
    <div className={styles.loadingOverlay}>
      <div className={styles.loading}>
        <Loader
          type="TailSpin"
          color="#6572e0"
          height={80}
          width={80}
        />
      </div>
    </div>
  )
}

function HomeScreen() {
  const [mapLoaded, setMapLoaded] = useState(false)
  const [lngLat, setLngLat] = useState({ lat: null, lng: null });
  const [currentDigitalAddress, setCurrentDigitalAddress] = useState(null);
  const [sheetOpen, setSheetOpen] = useState(false);
  const [modalCopy, setModalCopy] = useState(false);
  const [modalShare, setModalShare] = useState(false);
  const [modalBookmark, setModalBookmark] = useState(false);
  const [isSaved, setIsSaved] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const search = useLocation().search;
  const [savedAddresses, setSaveAddresses] = useState([]);

  const name = new URLSearchParams(search).get('name');
  const profileImage = new URLSearchParams(search).get('profile_image');
  const token = new URLSearchParams(search).get('token');
  const uuid = new URLSearchParams(search).get('uuid');
  const q = new URLSearchParams(search).get('q');

  const isGuest = !uuid || !token

  const decodeLngLat = useCallback(async (lng, lat) => {
    setLngLat({ lng, lat })

    // encode position using api
    try {
      const response = await addressAxios.get(requests.encode, {
        params: {
          lat: lat,
          lon: lng
        }
      });
      const result = response.data
      if(result.status === 'success') {
        setCurrentDigitalAddress(result.digital_address)
        setSheetOpen(true)
      } else if (result.status === 'error') {
        toast.error(result.error_reason);
      }
    } catch (err) {
      toast.error(err.message);
    }
  }, [])

  const handleGeolocate = (position) => {
    decodeLngLat(position.coords.longitude, position.coords.latitude)
  }

  const handleMapClick = (evt) => {
    decodeLngLat(evt.lngLat.lng, evt.lngLat.lat)
  }

  const handleSearch = useCallback(async (val) => {
    if (val.length === 10) {
      const response = await addressAxios.get(requests.decode, {
        params: {
          digital_address: val
        }
      });
      const result = response.data
      const { status, address_input, reverse_lat_lon, reason } = result
      if(status === 'success') {
        const latlon_arr = reverse_lat_lon.split(',')
        setLngLat({
          lat: latlon_arr[0],
          lng: latlon_arr[1]
        })
        setCurrentDigitalAddress(address_input)
        setSheetOpen(true)
      } else if (status === 'error') {
        toast.error(reason);
      }
    }
  }, []);

  const handleDirection = useCallback((coords) => {
    const mapURL = `https://www.google.com/maps/dir/Current+Location/${coords.lat},${coords.lng}`
    const win = window.open(mapURL, '_blank');
    if (win) {
      win.focus();
    }
  }, []);

  const handleBookmark = useCallback(() => {
    setModalBookmark(true)
  }, [])

  const handleSaveSuccess = useCallback((formState) => {
    const payload = {
      ...formState,
      user_uuid: uuid,
      token,
      lat: lngLat.lat,
      lng: lngLat.lng,
      digital_address: currentDigitalAddress
    }

    const formData = new FormData();
    for (const key in payload ) {
      formData.append(key, payload[key]);
    }

    baseAxios.post(requests.saveAddress, formData)
      .then(({ data }) => {
        const created = {
          id: data,
          ...payload
        }
        setSaveAddresses([...savedAddresses, created])
        setIsSaved(true)
      })
      .catch((err) => {
        console.log(err.message);
      })
  }, [currentDigitalAddress, lngLat, token, uuid, savedAddresses])

  const handleDeleteAddress = useCallback((address) => {
    const { id } = address
    const payload = {
      id,
      user_uuid: uuid,
      token
    }
    const formData = new FormData();
    for (const key in payload ) {
      formData.append(key, payload[key]);
    }
    baseAxios.post(requests.deleteAddress, formData)
      .then(({ data }) => {
        const deletedId = data
        const updated = savedAddresses.filter(address => address.id !== deletedId)
        setSaveAddresses(updated)
        setIsSaved(true)
      })
      .catch((err) => {
        console.log(err.message);
      })
  }, [savedAddresses, uuid, token])

  const handleCopy = useCallback((digitalAddress) => {
    copy(digitalAddress);
    setModalCopy(true)
  }, [])

  const handleShare = useCallback((digitalAddress) => {
    const shareURL = digitalAddress ? `https://minimap.loca.la/?q=${digitalAddress}` : null
    const shareText = digitalAddress ? `Digital Address: ${digitalAddress}\nGo to the maps: ` : null
  
    copy(`${shareText}${shareURL}`);
    setModalShare(true)
  }, [])

  const handleSaveListClick = useCallback(() => {
    setDrawerOpen(true)
  }, [])

  const handleCloseModalSave = () => {
    setModalBookmark(false)

    if (isSaved) {
      setIsSaved(false)
    }
  }

  useEffect(() => {
    if (q) {
      // move to q location if specified
      handleSearch(q);
    } else if ("geolocation" in navigator) {
      // get user's current position
      navigator.geolocation.getCurrentPosition((position) => {
        setLngLat({
          lng: position.coords.longitude,
          lat: position.coords.latitude
        })
      });
    }
  }, [q, handleSearch]);

  useEffect(() => {
    // retrieve user's existing saved address
    if (!isGuest) {
      const params = { token, uuid }
      baseAxios.get(requests.getAddress, { params })
        .then(({ data }) => {
          setSaveAddresses(data)
        })
        .catch((err) => {
          console.log(err.message);
        })
    }
  }, [isGuest, token, uuid]);

  return (
    <>
      <Map
        lngLat={lngLat}
        sheetOpen={sheetOpen}
        handleGeolocate={handleGeolocate}
        handleMapClick={handleMapClick}
        onLoaded={() => setMapLoaded(true)}
      />
      {
        !mapLoaded ?
          <LoadingIndicator />
        : <>
            <Search name={name} profileImage={profileImage} handleSearch={handleSearch} />
            { !isGuest && (
              <>
                <SavedListButton
                  sheetOpen={sheetOpen}
                  handleSaveListClick={handleSaveListClick}
                /> 
                <SavedListDrawer
                  open={drawerOpen}
                  close={() => setDrawerOpen(false)}
                  savedAddresses={savedAddresses}
                  handleDirection={handleDirection}
                  handleDeleteAddress={handleDeleteAddress}
                />
                <Modal
                  header={!isSaved && currentDigitalAddress}
                  open={modalBookmark}
                  close={handleCloseModalSave}
                >
                  { isSaved ?
                    <ModalSuccessView message={`Address ${ currentDigitalAddress } has been saved`} />
                    :
                    <BookmarkForm onSuccess={handleSaveSuccess} />
                  }
                </Modal>
              </>
            )}
            <Sheet
              open={sheetOpen}
              close={() => setSheetOpen(false)}
              digitalAddress={currentDigitalAddress}
              isGuest={isGuest}
              handleDirection={() => handleDirection(lngLat)}
              handleBookmark={handleBookmark}
              handleCopy={() => handleCopy(currentDigitalAddress)}
              handleShare={() => handleShare(currentDigitalAddress) }
            />
            <ToastContainer
              position="top-center"
              autoClose={5000}
              closeOnClick
              pauseOnFocusLoss
              draggable
            />
            <Modal
              open={modalCopy}
              close={() => setModalCopy(false)}
            >
              <ModalSuccessView message="Address has been copied to clipboard" />
            </Modal>
            <ModalShare
              open={modalShare}
              close={() => setModalShare(false)}
            />
          </>
      }
    </>
  )
}

export default HomeScreen
