import axios from "axios";
import { baseURL, openAddressURL } from "./api";

const baseAxios = axios.create({
  baseURL
});
const addressAxios = axios.create({
  baseURL: openAddressURL
});

export {
  baseAxios,
  addressAxios
}
