import React from 'react';
import { CheckIcon } from 'components'

function ModalSuccessView({ message }) {
  return (
    <>
      <CheckIcon />
      <div>{message}</div>
    </>
  )
}

export default ModalSuccessView;
