import React from 'react'
import styles from './Sheet.module.css'
import { BottomSheet } from 'react-spring-bottom-sheet'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowAltCircleRight, faBookmark, faCopy, faShareSquare } from '@fortawesome/free-regular-svg-icons'

function Sheet({ open, close, digitalAddress, isGuest, handleDirection, handleBookmark, handleCopy, handleShare }) {
  const shareURL = digitalAddress ? `https://minimap.loca.la/?q=${digitalAddress}` : null
  const shareText = digitalAddress ? `Digital Address: ${digitalAddress}\nGo to the maps: ` : null

  const handleShareClick = () => {
    if (navigator && navigator.share !== undefined) {
      navigator.share({
        title: '',
        text: shareText,
        url: shareURL
      }).then(() => console.log('Successful share'))
      .catch(error => console.log('Error sharing:', error))
    } else {
      handleShare()
    }
  }

  return (
    <BottomSheet open={open} onDismiss={close} blocking={false}>
      <div className={styles.root}>
        <h3>{digitalAddress}</h3>
        <div className={styles.separator} />
        <div className={styles.grid}>
          <button onClick={(handleDirection)}>
            <FontAwesomeIcon icon={faArrowAltCircleRight} color="#436AD1" /> Directions
          </button>
          {
            !isGuest && 
              <button onClick={handleBookmark}>
                <FontAwesomeIcon icon={faBookmark} color="#436AD1" /> Save
              </button>
          }
          <button onClick={handleCopy}>
            <FontAwesomeIcon icon={faCopy} color="#436AD1" /> Copy
          </button>
          <button onClick={handleShareClick}>
            <FontAwesomeIcon icon={faShareSquare} color="#436AD1" /> Share
          </button>
        </div>
      </div>
    </BottomSheet>
  )
}

export default Sheet
