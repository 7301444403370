const baseURL = "https://minimap.loca.la"
const openAddressURL = "https://openaddress.loca.la";

const requests = {
  encode: "/encode",
  decode: "/decode",
  getAddress: "/php/get-address.php",
  saveAddress: "/php/save-address.php",
  deleteAddress: "/php/delete-address.php",
};

export { baseURL, openAddressURL, requests };
