import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import 'mapbox-gl/dist/mapbox-gl.css';
import 'react-spring-bottom-sheet/dist/style.css'
import 'react-toastify/dist/ReactToastify.min.css';
import "rc-drawer/assets/index.css";
import 'react-swipeable-list/dist/styles.css';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

import App from './App';
import reportWebVitals from './reportWebVitals';

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
