import React, { useState, useCallback, useRef, useEffect } from 'react'
import './Map.css';
import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;

const mapConfig = {
  style: 'mapbox://styles/loca/ckw82sjo44rva15p6b7mgyb1m',
  lat: 19.8563,
  lng: 102.4955,
  zoom: 13
}

function Map({ lngLat, sheetOpen, handleGeolocate, handleMapClick, onLoaded }) {
  const mapContainer = useRef(null);
  const map = useRef(null);
  const [locationMarker, setLocationMarker] = useState(null);
  const [prevLngLat, setPrevLngLat] = useState({ lat: null, lng: null });

  const createMarker = useCallback((lngLat) => {
    if (map.current) {
      // Pan to coordinates
      map.current.flyTo({
        center: lngLat,
        zoom: mapConfig.zoom,
        bearing: 0,
        pitch: 0
      });

      const el = document.createElement('div');
      el.className = 'marker';

      // Add marker to map
      const marker = new mapboxgl.Marker(el)
        .setLngLat(lngLat)
        .addTo(map.current);
      return marker
    }
  }, [])

  useEffect(() => {
    if (map.current) return; // wait for map to initialize
    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: mapConfig.style,
      center: [mapConfig.lng, mapConfig.lat],
      zoom: mapConfig.zoom,
      attributionControl: false
    });
    
    // Add navigation control
    map.current.dragRotate.disable();
    map.current.touchZoomRotate.disableRotation();
    map.current.addControl(new mapboxgl.NavigationControl(), 'bottom-right');

    // Add geolocate control
    const geolocate = new mapboxgl.GeolocateControl({
      positionOptions: {
        enableHighAccuracy: true
      },
      trackUserLocation: false,
      showUserHeading: false,
      showAccuracyCircle: false,
      showUserLocation: false
    })
    geolocate.on('geolocate', handleGeolocate);
    map.current.addControl(geolocate, 'bottom-right');
    map.current.on('load', () => {
      map.current.resize();
      setTimeout(() => {
        onLoaded()
      }, 1500)
    });
    map.current.on('click', handleMapClick);
  });

  useEffect(() => {
    if (lngLat.lng !== prevLngLat.lng || lngLat.lat !== prevLngLat.lat) {
      if (locationMarker) {
        locationMarker.remove()
      }
      const marker = createMarker(lngLat)
      setLocationMarker(marker)
      setPrevLngLat(lngLat)
    }
  }, [lngLat, prevLngLat, locationMarker, createMarker]);

  useEffect(() => {
    const els = document.getElementsByClassName('mapboxgl-ctrl-bottom-right')
    if(els.length) {
      const el = els[0]
      if (sheetOpen) {
        el.classList.add('slide-up')
      } else {
        if (el.classList.contains('slide-up')) {
          el.classList.remove('slide-up')
        }
      }
    }
  }, [sheetOpen]);

  return (
    <div ref={mapContainer} className="map-container" />
  )
}

export default Map
